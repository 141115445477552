import { msalInstance } from "../";
import * as msal from "@azure/msal-browser";

export async function setupRequestAsync(method, ajaxOptions) {
  ajaxOptions.xhrFields = { withCredentials: true };
  await addAuthHeaderAsync(ajaxOptions);
}

async function addAuthHeaderAsync(ajaxOptions) {
  const tokenResponse = await acquireTokenAsync();
  if (tokenResponse && ajaxOptions) {
    ajaxOptions.headers = {
      Authorization: "Bearer " + tokenResponse.accessToken,
    };
  }
}

export function getAccount() {
  const accounts = msalInstance.getAllAccounts();
  if (accounts && accounts.length) {
    return accounts[0];
  }
}

export async function acquireTokenAsync() {
  const account = getAccount();
  const request = {
    account,
    scopes: window.env.squireApiConfig.scopes,
  };
  return await msalInstance.acquireTokenSilent(request).catch(async (error) => {
    // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
    // due to consent or interaction required ONLY
    if (error instanceof msal.InteractionRequiredAuthError) {
      return await msalInstance.acquireTokenRedirect(request);
    } else {
      console.error("Non-interactive error:", error.errorCode);
    }
  });
}
