import { createStore } from "devextreme-aspnet-data-nojquery";
import { setupRequestAsync } from "../../utils/auth";
import { fetchApi } from "../../utils/fetch-api";

const {
  squireApiConfig: { url },
} = window.env;

export default class ContactsApiService {
  getContactsDataSource() {
    return createStore({
      key: "contactId",
      loadUrl: `${url}/v1/contacts/`,
      insertUrl: `${url}/v1/contacts/`,
      updateUrl: `${url}/v1/contacts/`,
      deleteUrl: `${url}/v1/contacts/`,
      onBeforeSend: async (method, ajaxOptions) => 
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  async getContactForEditing(contactId) {
    const fetchUrl = `${url}/v1/contacts/${contactId}`;
    return await fetchApi(fetchUrl, "GET");
  }

  getEmailAddressesDataSource(contactId) {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/contacts/${contactId}/email-addresses`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions)
    });
  }

  getPhoneNumbersDataSource(contactId) {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/contacts/${contactId}/phone-numbers`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions)
    });
  }

  getClientRelationshipTypesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/contacts/client-relationship-types`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions)
    });
  }

  getContactPrioritiesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/contacts/contact-priorities`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions)
    });
  }

  getRepresentativesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/contacts/representatives`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions)
    });
  }

  getPhoneTypesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/contacts/phone-types`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions)
    });
  }

  getProjectContactTypesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/contacts/project-contact-types`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions)
    });
  }
}