import 
  React,
  {
    useState,
    useRef,
    useEffect,
  }
from "react";
import DataGrid, {
  Column,
  FilterRow,
  Editing,
  Lookup,
  Button as GridButton,
  GroupPanel,
  Toolbar,
  Selection,
  Item,
  Grouping,
  Scrolling,
  Paging,
  Summary,
  TotalItem,
  GroupItem,
} from "devextreme-react/data-grid";
import
{
  Popup,
  ToolbarItem
} from 'devextreme-react/popup';
import Form, {
  SimpleItem,
  GroupItem as FormGroupItem,
  Label
} from 'devextreme-react/form';
import {
  Validator,
  RequiredRule,
} from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import { SelectBox } from 'devextreme-react/select-box';
import ScrollView from "devextreme-react/scroll-view";
import { NumberBox } from "devextreme-react/number-box";
import TextArea from 'devextreme-react/text-area';
import TextBox from 'devextreme-react/text-box';
import {
  Button
} from 'devextreme-react/button';
import CollapsiblePanel from '../../components/collapsible-panel/collapsible-panel';
import {
  currencyFormat,
  dateTimeFormat,
} from "../../utils/formatting";
import { setTitleClass } from "../../utils/ui";
import { calculateGridHeight } from '../../utils/ui';
import styles from "./estimates.module.scss";

export default function Estimates(props) {
  const [showEstimateForm, setShowEstimateForm] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [editingEstimateId, setEditingEstimateId] = useState();
  const [showAddMultipleScopesForm, setShowAddMultipleScopesForm] = useState(false);
  const [showNotesPopup, setShowNotesPopup] = useState(false);
  const [editingEstimateItemId, setEditingEstimateItemId] = useState();
  const [editingItemName, setEditingItemName] = useState();
  const [editingItemNotes, setEditingItemNotes] = useState();
  const [showCreateFormProperties, setShowCreateFormProperties] = useState(false);
  const [selectedOpportunityId, setSelectedOpportunityId] = useState();
  const [selectedOpportunityData, setSelectedOpportunityData] = useState({});

  const editingEstimate = useRef(null);

  const estimatesApiService = new props.estimatesApiService();
  const estimatesDataSource = estimatesApiService.getEstimatesDataSource();
  const opportunitiesDataSource = estimatesApiService.getOpportunitiesDataSource();
  const templatesDataSource = estimatesApiService.getTemplatesDataSource();
  const subtradesDataSource = estimatesApiService.getGroupedSubtradesDataSource();
  const estimateItemsDataSource = estimatesApiService.getEstimateItemsDataSource(editingEstimateId);
  const estimateScopesDataSource = estimatesApiService.getEstimateScopesDataSource();
  const companiesDataSource = estimatesApiService.getCompaniesDataSource();
  const getOpportunityById = estimatesApiService.getOpportunityById;
  const constructionTypesDataSource = estimatesApiService.getConstructionTypesDataSource();
  const industryTypesDataSource = estimatesApiService.getIndustryTypesDataSource();
  const jobTypesDataSource = estimatesApiService.getJobTypesDataSource();
  const projectContractTypesDataSource = estimatesApiService.getProjectContractTypesDataSource();

  const projectStatusLookupApiService = new props.projectStatusLookupApiService();
  const projectStatusLookupDataSource = projectStatusLookupApiService.getProjectStatusLookupDataSource();

  const estimateItemsGridRef = useRef(null);
  const addScopesGridRef = useRef(null);
  const createEstimateOpportunitySelectBoxRef = useRef(null);
  const createEstimateTemplateSelectBoxRef = useRef(null);
  const estimateItemNotesTextAreaRef = useRef(null);
  const estimatesGridName = "EstimatesGrid";
  const estimatesGridRef = useRef(null);
  const createEstimateNameTextBoxRef = useRef(null);
  const createEstimateOverheadNumberBoxRef = useRef(null);
  const createEstimateProfitNumberBoxRef = useRef(null);
  const createValidationGroupRef = useRef(null);
  const createEstimateConstructionTypeSelectBoxRef = useRef(null);
  const createEstimateJobTypeSelectBoxRef = useRef(null);
  const createEstimateContractTypeSelectBoxRef = useRef(null);
  const createEstimateIndustryTypeSelectBoxRef = useRef(null);
  const editFormRef = useRef(null);


  useEffect(() => {
    if (selectedOpportunityId !== undefined && selectedOpportunityId !== null) {
      async function loadOpportunityData() {
        var opportunity = await getOpportunityById(selectedOpportunityId);
        setSelectedOpportunityData(opportunity);
      };
      loadOpportunityData();
      setShowCreateFormProperties(true);
    }
  }, [
    selectedOpportunityId,
    getOpportunityById
  ]);

  async function loadEstimateData(id) {
    var estimateData = await estimatesApiService.getEstimateForEditing(id);
    editingEstimate.current = estimateData;
  };



  async function viewEstimateClick(e) {
    setEditingEstimateId(e.row.data.id);
    await loadEstimateData(e.row.data.id)
      .then(() => {
        setShowEstimateForm(true);
      });
  }

  function createEstimateClick() {
    setShowCreateForm(true);
  }

  const createEstimateButtonOptions = {
    text: "Create Estimate",
    width: "200px",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      createEstimateClick();
    }
  }

  function addEstimateScopeClick() {
    setShowAddMultipleScopesForm(true);
  }

  const addEstimateScopeButtonOptions = {
    text: "Add Scope...",
    width: "200px",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      addEstimateScopeClick();
    }
  }

  function createFormContinueButtonClick() {
    var selectedOpportunityId = createEstimateOpportunitySelectBoxRef.current.instance.option("value").id;
    var selectedTemplateId = createEstimateTemplateSelectBoxRef.current.instance.option("value").id;
    var estimateName = createEstimateNameTextBoxRef.current.instance.option("value");
    var overheadPercentage = createEstimateOverheadNumberBoxRef.current.instance.option("value");
    var profitPercentage = createEstimateProfitNumberBoxRef.current.instance.option("value");
    var constructionTypeValueInstance = createEstimateConstructionTypeSelectBoxRef.current.instance.option("value");
    var constructionTypeId;
    if (constructionTypeValueInstance !== undefined && constructionTypeValueInstance !== null) {
      constructionTypeId = constructionTypeValueInstance.id;
    } else {
      constructionTypeId = -1;
    }
    var contractTypeValueInstance = createEstimateContractTypeSelectBoxRef.current.instance.option("value");
    var contractTypeId;
    if (contractTypeValueInstance !== undefined && contractTypeValueInstance !== null) {
      contractTypeId = contractTypeValueInstance.id;
    } else {
      contractTypeId = -1;
    }
    var jobTypeValueInstance = createEstimateJobTypeSelectBoxRef.current.instance.option("value");
    var jobTypeId;
    if (jobTypeValueInstance !== undefined && jobTypeValueInstance !== null) {
      jobTypeId = jobTypeValueInstance.id;
    } else {
      jobTypeId = -1;
    }
    var industryTypeValueInstance = createEstimateIndustryTypeSelectBoxRef.current.instance.option("value");
    var industryTypeId;
    if (industryTypeValueInstance !== undefined && industryTypeValueInstance !== null) {
      industryTypeId = industryTypeValueInstance.id;
    } else {
      industryTypeId = -1;
    }
    estimatesApiService.createEstimateForOpportunity(
      selectedOpportunityId,
      selectedTemplateId,
      estimateName,
      overheadPercentage,
      profitPercentage,
      jobTypeId,
      industryTypeId,
      constructionTypeId,
      contractTypeId
    )
      .then(() => {
        setShowCreateForm(false);
      });
  }

  function createFormCancelButtonClick() {
    setSelectedOpportunityData({});
    setSelectedOpportunityId();
    setShowCreateFormProperties(false);
    setShowCreateForm(false);
  }

  const createFormContinueButtonOptions = {
    text: "Continue",
    type: "default",
    stylingMode: "outlined",
    onClick(e) {
      var result = createValidationGroupRef.current.instance.validate();
      if (result.isValid) {
        createFormContinueButtonClick();
      }
    }
  }

  const createFormCancelButtonOptions = {
    text: "Cancel",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      createFormCancelButtonClick();
    }
  }

  async function opportunitySelectBoxValueChanged(e) {
    setSelectedOpportunityId(e.value.id);
  }

  function renderCreateForm() {
    if (showCreateForm) {
      return (
        <Popup
          visible={showCreateForm}
          showCloseButton={false}
          title="Create Estimate"
          onHiding={() => {
            setShowCreateForm(false);
          }}
        > 
          
          <ValidationGroup
            name="createGroup"
            ref={createValidationGroupRef}
          >
            <SelectBox
              dataSource={opportunitiesDataSource}
              displayExpr="name"
              ref={createEstimateOpportunitySelectBoxRef}
              onValueChanged={opportunitySelectBoxValueChanged}
              placeholder="Please select an opportunity"
              searchEnabled={true}
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </SelectBox>
            <div className={styles.spacerDiv} />
            <TextBox
              ref={createEstimateNameTextBoxRef}
              visible={showCreateFormProperties}
              value={selectedOpportunityData.name}
              label="Name"
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </TextBox>
            <div className={styles.spacerDiv} />
            <NumberBox
              visible={showCreateFormProperties}
              value={selectedOpportunityData.overheadPercentage}
              label="Overhead Percentage"
              ref={createEstimateOverheadNumberBoxRef}
            />
            <div className={styles.spacerDiv} />
            <NumberBox
              visible={showCreateFormProperties}
              value={selectedOpportunityData.profitPercentage}
              label="Profit Percentage"
              ref={createEstimateProfitNumberBoxRef}
            />
            <div className={styles.spacerDiv} />
            <SelectBox
              dataSource={constructionTypesDataSource}
              displayExpr="value"
              label="Construction Type"
              ref={createEstimateConstructionTypeSelectBoxRef}
              visible={showCreateFormProperties}
            />
            <div className={styles.spacerDiv} />
            <SelectBox
              dataSource={jobTypesDataSource}
              label="Job Type"
              displayExpr="name"
              ref={createEstimateJobTypeSelectBoxRef}
              visible={showCreateFormProperties}
            />
            <div className={styles.spacerDiv} />
            <SelectBox
              dataSource={projectContractTypesDataSource}
              label="Contract Type"
              displayExpr="value"
              ref={createEstimateContractTypeSelectBoxRef}
              visible={showCreateFormProperties}
            />
            <div className={styles.spacerDiv} />
            <SelectBox
              dataSource={industryTypesDataSource}
              displayExpr="name"
              label="Industry"
              ref={createEstimateIndustryTypeSelectBoxRef}
              visible={showCreateFormProperties}
            />
            <div className={styles.spacerDiv} />
            <SelectBox
              dataSource={templatesDataSource}
              displayExpr="name"
              label="Template:"
              ref={createEstimateTemplateSelectBoxRef}
              visible={showCreateFormProperties}
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </SelectBox>
          </ValidationGroup>
          
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={createFormContinueButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={createFormCancelButtonOptions}
          />
        </Popup>
      );
    }
  }

  function refreshEstimateItemsGrid() {
    estimateItemsGridRef.current.instance.refresh();
  }

  const addMultipleScopesContinueButtonOptions = {
    text: "Continue",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      addMultipleScopesContinueClick();
    }
  }

  const addMultipleScopesCancelButtonOptions = {
    text: "Cancel",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      addMultipleScopesCancelClick();
    }
  }

  function addMultipleScopesContinueClick() {
    var ids = addScopesGridRef.current.instance.getSelectedRowKeys();
    estimatesApiService.addMultipleEstimateScopes(editingEstimateId, ids)
      .then(() => {
        refreshEstimateItemsGrid();
        setShowAddMultipleScopesForm(false);
      });
  }

  function addMultipleScopesCancelClick() {
    setShowAddMultipleScopesForm(false);
  }

  function renderAddMultipleScopeForm() {
    if (showAddMultipleScopesForm) {
      return (
        <Popup
          visible={showAddMultipleScopesForm}
          showCloseButton={false}
          title="Add Estimate Scope"
          onHiding={() => {
            setShowAddMultipleScopesForm(false);
          }}
        >
          <DataGrid
            dataSource={estimateScopesDataSource}
            ref={addScopesGridRef}
          >
            <Selection
              mode="multiple"
              showCheckBoxesMode="always"
            />
            <Grouping autoExpandAll={false} />
            <Scrolling
              mode='virtual'
              rowRenderingMode='virtual'
            />
            <Paging defaultPageSize={50} />
            <Column
              dataField="divisionName"
              groupIndex={0}
              allowEditing={false}
            />
            <Column
              dataField="name"
              sortIndex={0}
              sortOrder="asc"
            />
          </DataGrid>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={addMultipleScopesContinueButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={addMultipleScopesCancelButtonOptions}
          />
        </Popup>
      );
    }
  }

  function estimateFormFieldDataChanged(e) {
    estimatesApiService.updateEstimate(editingEstimateId, e.dataField, e.value)
      .then(() => {
        loadEstimateData(editingEstimateId)
          .then(() => {
            editFormRef.current.instance.updateData(editingEstimate.current)
          });
      });
  }

  const editingEstimateCompanyOptions = {
    dataSource: companiesDataSource,
    searchEnabled: true,
    displayExpr: "name",
    valueExpr: "id"
  }


  function viewNotesPopupClick(e) {
    setEditingEstimateItemId(e.row.data.id);
    setEditingItemNotes(e.row.data.notes);
    setEditingItemName(e.row.data.sectionName);
    setShowNotesPopup(true);
  }

  const notesPopupCancelButtonOptions = {
    text: "Cancel",
    onClick() {
      notesPopupCancelButtonClick();
    }
  }

  const notesPopupSaveButtonOptions = {
    text: "Save",
    onClick() {
      notesPopupSaveButtonClick();
    }
  }

  function notesPopupCancelButtonClick() {
    setShowNotesPopup(false);
    //setEditingItemName("");
    //setEditingItemNotes();
  }

  function notesPopupSaveButtonClick() {
    var newNotes = estimateItemNotesTextAreaRef.current.instance.option("value");
    estimatesApiService.updateEstimateItemNotes(editingEstimateId, editingEstimateItemId, newNotes)
      .then(() => {
        setShowNotesPopup(false);
      });
  }

  function renderNotesPopup() {
    if (showNotesPopup) {
      return (
        <Popup
          visible={showNotesPopup}
          showCloseButton={false}
          title={"Notes for " + editingItemName}
        >
          <TextArea
            defaultValue={editingItemNotes}
            ref={estimateItemNotesTextAreaRef}
            width="100%"
            height="100%"
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={notesPopupSaveButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={notesPopupCancelButtonOptions}
          />
        </Popup>
      );
    }
  }

  function estimateItemDataGridOnCellPrepared(e) {
    if (e.rowType === "data" && e.column.buttons && e.column.name === "notesButton") {
      if (e.data.notes !== null) {
        e.cellElement.bgColor = "#75eb7b";
      }
    }
    
  }

  function estimateItemsOnEditorPreparing(e) {
    if (e.dataField === "amount") {
      if (e.row.data.companyId === null) {
        e.editorOptions.disabled = true;
        e.editorOptions.placeholder = "select a subtrade";
      }
    }
  }

  function estimateItemsOnRowUpdated(e) {
    if (e.data.hasOwnProperty('amount')) {
      loadEstimateData(editingEstimateId)
        .then(() => {
          editFormRef.current.instance.updateData(editingEstimate.current)
        });
    }
  }


  const currencyOnlyEditorOptions = {
    format: currencyFormat
  }

  const percentageOnlyEditorOptions = {
    format: "#'%'"
  }

  const editingEstimateJobTypeSelectBoxOptions = {
    dataSource: jobTypesDataSource,
    searchEnabled: true,
    displayExpr: "name",
    valueExpr: "id"
  }

  const editingEstimateConstructionTypeSelectBoxOptions = {
    dataSource: constructionTypesDataSource,
    searchEnabled: true,
    displayExpr: "value",
    valueExpr: "id"
  }

  const editingEstimateIndustryTypeSelectBoxOptions = {
    dataSource: industryTypesDataSource,
    searchEnabled: true,
    displayExpr: "name",
    valueExpr: "id"
  }

  function EditFormCloseClick() {
    setShowEstimateForm(false);
  }

  function RenderEstimateFormTitle() {
    return (
      <div className={styles.popupTitleDiv} >
        <div className={styles.popupTitleNameDiv} >Estimate for {editingEstimate.current.name}</div>
        <div className={styles.popupTitleSpacerDiv}></div>
        <div className={styles.popupTitleCloseDiv} >
          <Button
            text="X"
            onClick={EditFormCloseClick}
          />
        </div>
      </div>
      )
  }

  const editingEstimateContractTypeSelectBoxOptions = {
    dataSource: projectContractTypesDataSource,
    searchEnabled: true,
    displayExpr: "value",
    valueExpr: "id"
  }

  const deleteMultipleScopesButtonOptions = {
    text: "Delete Scopes ...",
    width: "200px",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      deleteMultipleEstimatesScopesClick();
    }
  }

  function deleteMultipleEstimatesScopesClick() {
    var selectedKeys = estimateItemsGridRef.current.instance.getSelectedRowKeys().toString();
    if (window.confirm("Are you sure you want to delete the selected estimate scopes?")) {
      estimatesApiService.deleteMultipleEstimateScopes(selectedKeys)
        .then(() => {
          refreshEstimateItemsGrid();
        });
    }
    
  }

  const hideSimpleItemOptions = {
    visible: false
  }

  const editingEstimateBidDateOptions = {
    dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssx",
    type: "datetime"
  }

  function renderEstimateForm() {
    if (showEstimateForm) {

      return (
        <Popup
          fullScreen={true}
          visible={showEstimateForm}
          showCloseButton={true}
          titleComponent={RenderEstimateFormTitle}
          onHiding={() => {
            setShowEstimateForm(false);
          }}
        >
          <ScrollView width="100%" height="100%">

            <CollapsiblePanel
              title="Estimate Details"
              expanded={true}
            >
              <Form
                formData={editingEstimate.current}
                onFieldDataChanged={estimateFormFieldDataChanged}
                ref={editFormRef}
                colCount={3}
                labelMode="outside"
                labelLocation="left"
              >
                <FormGroupItem>
                  <SimpleItem dataField="name" />
                  <SimpleItem
                    dataField="companyId"
                    editorType="dxSelectBox"
                    editorOptions={editingEstimateCompanyOptions}
                  />
                  <SimpleItem
                    dataField="bidDate"
                    editorType="dxDateBox"
                    editorOptions={editingEstimateBidDateOptions}
                  />
                  <SimpleItem
                    dataField="constructionTypeId"
                    editorType="dxSelectBox"
                    editorOptions={editingEstimateConstructionTypeSelectBoxOptions}
                  />
                  <Item
                    dataField="projectContractTypeId"
                    editorType="dxSelectBox"
                    editorOptions={editingEstimateContractTypeSelectBoxOptions}
                  />
                  <Item
                    dataField="jobTypeId"
                    editorType="dxSelectBox"
                    editorOptions={editingEstimateJobTypeSelectBoxOptions}
                  />
                  <SimpleItem
                    dataField="industryTypeId"
                    editorType="dxSelectBox"
                    editorOptions={editingEstimateIndustryTypeSelectBoxOptions}
                  />
                </FormGroupItem>
                <FormGroupItem>
                  <SimpleItem
                    dataField="notes"
                    editorType="dxTextArea"
                  />
                </FormGroupItem>
                <FormGroupItem colCount="2">
                  <SimpleItem
                    editorType="dxTextBox"
                    editorOptions={hideSimpleItemOptions}
                  >
                    <Label text="Total Project Cost" alignment="right" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="totalProjectCost"
                    disabled={true}
                    editorOptions={currencyOnlyEditorOptions}
                  >
                    <Label visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="overheadPercentage"
                    editorOptions={percentageOnlyEditorOptions}
                  >
                    <Label text="Total Overhead" alignment="right" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="totalOverhead"
                    disabled={true}
                    editorOptions={currencyOnlyEditorOptions}
                  >
                    <Label visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="profitPercentage"
                    editorOptions={percentageOnlyEditorOptions}
                  >
                    <Label text="Total Profit" alignment="right" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="totalProfit"
                    disabled={true}
                    editorOptions={currencyOnlyEditorOptions}
                  >
                    <Label visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    editorType="dxTextBox"
                    editorOptions={hideSimpleItemOptions}
                  >
                    <Label text="Contingency Amount" alignment="right" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="contingencyAmount"
                    editorOptions={currencyOnlyEditorOptions}
                  >
                    <Label visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    editorType="dxTextBox"
                    editorOptions={hideSimpleItemOptions}
                  >
                    <Label text="Bid Sub Total" alignment="right" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="bidSubTotal"
                    disabled={true}
                    editorOptions={currencyOnlyEditorOptions}
                  >
                    <Label visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    editorType="dxTextBox"
                    editorOptions={hideSimpleItemOptions}
                  >
                    <Label text="Total GST" alignment="right" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="totalGST"
                    disabled={true}
                    editorOptions={currencyOnlyEditorOptions}
                  >
                    <Label visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    editorType="dxTextBox"
                    editorOptions={hideSimpleItemOptions}
                  >
                    <Label text="Bid Total" alignment="right" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="bidTotal"
                    disabled={true}
                    editorOptions={currencyOnlyEditorOptions}
                  >
                    <Label visible={false} />
                  </SimpleItem>
                </FormGroupItem>
              </Form>
            </CollapsiblePanel>
            <div className={styles.spacerDiv} />
            <CollapsiblePanel
              title="Estimate Sheet"
              expanded={true}
            >
              <DataGrid
                dataSource={estimateItemsDataSource}
                ref={estimateItemsGridRef}
                onCellPrepared={estimateItemDataGridOnCellPrepared}
                onEditorPreparing={estimateItemsOnEditorPreparing}
                onRowUpdated={estimateItemsOnRowUpdated}
                height="800px"
              >
                <Toolbar>
                  <Item
                    location="before"
                    widget="dxButton"
                    options={addEstimateScopeButtonOptions}
                  />
                  <Item
                    location="after"
                    widget="dxButton"
                    options={deleteMultipleScopesButtonOptions}
                  />
                </Toolbar>
                <Scrolling
                  mode='virtual'
                  rowRenderingMode='virtual'
                />
                <Selection 
                  mode="multiple"
                />
                <Paging defaultPageSize={50} />
                <Editing
                  mode="cell"
                  allowUpdating={true}
                  allowDeleting={true}
                />
                <Column type="buttons">
                  <GridButton name="delete" />
                </Column>
                <Column
                  dataField="divisionName"
                  groupIndex={0}
                  allowEditing={false}
                />
                <Column
                  dataField="sectionName"
                  allowEditing={false}
                  sortIndex={0}
                  sortOrder="asc"
                />
                <Column
                  type="buttons"
                  name="notesButton"
                >
                  <GridButton
                    text="Notes"
                    onClick={(e) => viewNotesPopupClick(e)}
                  />
                </Column>
                <Column
                  caption="Subtrade Name"
                  dataField="companyId"
                  showEditorAlways={true}
                  width="300px"
                >
                  <Lookup
                    dataSource={subtradesDataSource.store()}
                    valueExpr="companyId"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="amount"
                  name="amount"
                  showEditorAlways={false}
                  caption="Quotation"
                  width="250px"
                  format={currencyFormat}
                />
                <Summary>
                  <TotalItem
                    name="totalProjectCost"
                    column="amount"
                    summaryType="sum"
                    valueFormat={currencyFormat}
                    displayFormat="Estimate Total: {0}"
                  />
                  <GroupItem
                    column="amount"
                    summaryType="sum"
                    valueFormat={currencyFormat}
                    alignByColumn={true}
                    displayFormat="Division Total: {0}"
                  />
                </Summary>
              </DataGrid>
            </CollapsiblePanel>
          </ScrollView>
        </Popup>
      );
    }
  }

  return (
    <React.Fragment>
      {renderEstimateForm()}
      {renderCreateForm()}
      {renderAddMultipleScopeForm()}
      {renderNotesPopup()}
      <h2 className={setTitleClass()}>Estimates</h2>
      <DataGrid
        id={estimatesGridName}
        dataSource={estimatesDataSource}
        ref={estimatesGridRef}
        remoteOperations={true}
        height={() => calculateGridHeight(estimatesGridName)}
      >
        <Toolbar>
          <Item name="groupPanel" location="before" />
          <Item
            location="before"
            widget="dxButton"
            options={createEstimateButtonOptions}
          />
        </Toolbar>
        <Scrolling
          mode='virtual'
          rowRenderingMode='virtual'
        />
        <Paging defaultPageSize={50} />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <Column dataField="name" />
        <Column dataField="companyName" />
        <Column
          dataField="bidDate"
          defaultSortOrder="asc"
          dataType="datetime"
          format={dateTimeFormat}
        />
        <Column 
          dataField="statusId"
          caption="Status"
        >
          <Lookup
            dataSource={projectStatusLookupDataSource}
            valueExpr="id"
            displayExpr="value"
          />
        </Column>
        <Column
          type="buttons"
        >
          <GridButton
            text="View Estimate"
            onClick={(e) => viewEstimateClick(e) }
          />
        </Column>

      </DataGrid>

    </React.Fragment>
  );
}