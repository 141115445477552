import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./dx-styles.scss";
import { NavigationProvider } from "./contexts/navigation";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import { MsalProvider } from "@azure/msal-react";
import './themes/override.datagrid.scss';

function App() {
  return (
    <>
      <Content />
    </>
  );
}

export default function MainApp({ pca }) {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <MsalProvider instance={pca}>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </MsalProvider>
    </Router>
  );
}
