import { createStore } from "devextreme-aspnet-data-nojquery";
import { setupRequestAsync } from "../../utils/auth";
import { fetchApi } from "../../utils/fetch-api";
import DataSource from "devextreme/data/data_source";

const {
  squireApiConfig: { url },
} = window.env;

export default class EstimatesApiService {
  getEstimatesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimates`,
      updateUrl: `${url}/v1/estimates`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  getOpportunitiesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimates/opportunities`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  getTemplatesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimates/templates`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  async getEstimateForEditing(estimateId) {
    const fetchUrl = `${url}/v1/estimates/${estimateId}`;
    return await fetchApi(fetchUrl, "GET");
  }

  getEstimateItemsDataSource(estimateId) {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimates/${estimateId}/estimate-items`,
      updateUrl: `${url}/v1/estimates/${estimateId}/estimate-items`,
      deleteUrl: `${url}/v1/estimates/${estimateId}/estimate-items`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions)
    });
  }

  getEstimateScopesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimates/scopes`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  getGroupedSubtradesDataSource() {
    return new DataSource({
      store: createStore({
        key: "companyId",
        loadMode: "raw",
        loadUrl: `${url}/v1/estimates/subtrades`,
        onBeforeSend: async (method, ajaxOptions) =>
          await setupRequestAsync(method, ajaxOptions),
      }),
      group: "group"
    });
  }

  async addEstimateScopes(estimateId, ids) {
    const fetchUrl = `${url}/v1/estimates/add-scopes`;
    const formBody = new URLSearchParams({
      estimateId: estimateId,
      idsToAdd: ids.toString(),
    });
    return await fetchApi(fetchUrl, "POST", formBody);
  }

  async addMultipleEstimateScopes(estimateId, ids) {
    const fetchUrl = `${url}/v1/estimates/add-multiple-scopes`;
    const formBody = new URLSearchParams({
      estimateId: estimateId,
      idsToAdd: ids.toString(),
    });
    return await fetchApi(fetchUrl, "POST", formBody);
  }

  async deleteMultipleEstimateScopes(ids) {
    const fetchUrl = `${url}/v1/estimates/delete-multiple-scopes`;
    const formBody = new URLSearchParams({
      idsToDelete: ids.toString(),
    });
    return await fetchApi(fetchUrl, "DELETE", formBody)
  }

  async createEstimateForOpportunity(
    opportunityId,
    templateId,
    estimateName,
    overheadPercentage,
    profitPercentage,
    jobTypeId,
    industryTypeId,
    constructionTypeId,
    contractTypeId
  ) {
    const fetchUrl = `${url}/v1/estimates/create-estimate`;
    const formBody = new URLSearchParams({
      opportunityId: opportunityId,
      templateId: templateId,
      estimateName: estimateName,
      overheadPercentage: overheadPercentage,
      profitPercentage: profitPercentage,
      jobTypeId: jobTypeId,
      industryTypeId: industryTypeId,
      constructionTypeId: constructionTypeId,
      contractTypeId: contractTypeId
    });
    return await fetchApi(fetchUrl, "POST", formBody);
  }

  async updateEstimateItemNotes(estimateId, estimateItemId, notes) {
    const fetchUrl = `${url}/v1/estimates/${estimateId}/estimate-items`;
    const formBody = new URLSearchParams({
      key: estimateItemId,
      values: `{"notes":"${notes}"}`
    });
    return await fetchApi(fetchUrl, "PUT", formBody);
  }

  async updateEstimate(estimateId, datafield, value) {
    const fetchUrl = `${url}/v1/estimates/${estimateId}`;
    const formBody = new URLSearchParams({
      values: `{"${datafield}":"${value}"}`
    });
    return await fetchApi(fetchUrl, "PUT", formBody);
  }

  getCompaniesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimates/companies`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  async getOpportunityById(estimateId) {
    const fetchUrl = `${url}/v1/estimates/${estimateId}`;
    return await fetchApi(fetchUrl, "GET", null);
  }

  getConstructionTypesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimates/construction-types`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  getIndustryTypesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimates/industry-types`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  getJobTypesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimates/job-types`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  getProjectContractTypesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimates/project-contract-types`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }
}