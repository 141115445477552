import { createStore } from "devextreme-aspnet-data-nojquery";
import { fetchApi } from "../../utils/fetch-api";
import { setupRequestAsync } from "../../utils/auth";

const {
  squireApiConfig: { url },
} = window.env;

export default class PaymentApiService {
  getPaymentsDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/payments`,
      updateUrl: `${url}/v1/payments`,
      deleteUrl: `${url}/v1/payments`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  getAllPaymentsDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/payments/all-payments`,
      updateUrl: `${url}/v1/payments`,
      deleteUrl: `${url}/v1/payments`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  async updateStatus(status, ids) {
    const fetchUrl = `${url}/v1/payments/update-status`;
    const formBody = new URLSearchParams({
      status: status,
      ids: ids.toString(),
    });
    return await fetchApi(fetchUrl, "PUT", formBody);
  }
}