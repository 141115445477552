import React,
{
  useState,
  useRef,
} from "react";
import DataGrid, {
  Column,
  Editing,
  Lookup,
  Paging,
  Button,
  FilterRow,
  Scrolling,
  GroupPanel,
  Toolbar,
} from "devextreme-react/data-grid";
import TabPanel, {
  Item
} from "devextreme-react/tab-panel";
import Form, {
  GroupItem,
} from "devextreme-react/form";
import {
  Popup,
  ToolbarItem
} from 'devextreme-react/popup';
import { calculateGridHeight } from '../../utils/ui';
import { setTitleClass } from "../../utils/ui";

export default function Contacts(props) {
  const [editingContactData, setEditingContactData] = useState();
  const [showContactEditForm, setShowContactEditForm] = useState();


  const contactsApiService = new props.contactsApiService();
  const contactsDataSource = contactsApiService.getContactsDataSource();
  const clientRelationshipDataSource = contactsApiService.getClientRelationshipTypesDataSource();
  const contactPrioritiesDataSource = contactsApiService.getContactPrioritiesDataSource();
  const representativeDataSource = contactsApiService.getRepresentativesDataSource();
  const phoneTypesDataSource = contactsApiService.getPhoneTypesDataSource();
  const projectContactTypeDataSource = contactsApiService.getProjectContactTypesDataSource();

  

  const contactsGridName = "ContactsGrid";
  const contactsGridRef = useRef(null);
  const emailGridName = "EmailGrid";
  const emailGridRef = useRef(null);
  const phoneGridName = "PhoneGrid";
  const phoneGridRef = useRef(null);

  async function contactGridOnEditingStart(e) {
    await loadContactData(e.row.data.contactId)
      .then(() => {
        setShowContactEditForm(true);
      });
  }

  async function loadContactData(id) {
    var contactData = await contactsApiService.getContactForEditing(id);
    setEditingContactData(contactData);
  }

  const contactEditFormCancelButtonOptions = {
    text: "Cancel",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      setShowContactEditForm(false);
    }
  }

  const contactEditFormSaveButtonOptions = {
    text: "Save",
    type: "default",
    stylingMode: "outlined",
    useSubmitBehavior: true,
  }


  function phoneGridRowInserting(e) {
    editingContactData.newPhoneNumbers.push({ id: e.data.__KEY__, number: e.data.number })
  }

  function phoneGridRowUpdating(e) {

  }

  function phoneGridRowDeleting(e) {
  }

  const contactRoleTypeEditorOptions = {
    dataSource: projectContactTypeDataSource,
    searchEnabled: true,
    displayExpr: "value",
    valueExpr: "id"
  }

  function CompanyContactItem({ data }) {
    const CompanyContact = data;
    
    return (
      <div>
        <Form
          formData={CompanyContact}
          colCount={3}
        >
          <GroupItem>
            <Item dataField="jobTitle" />
            <Item
              dataField="roleId"
              editorType="dxSelectBox"
              editorOptions={contactRoleTypeEditorOptions}
            />
            <Item dataField="location" />
          </GroupItem>
          <GroupItem caption="Company Addresses">
          </GroupItem>
          <GroupItem>
            <GroupItem colCount={1} caption={'Phone Numbers'}>
              <DataGrid
                id={phoneGridName}
                ref={phoneGridRef}
                dataSource={CompanyContact.phoneNumbers}
                onRowInserting={phoneGridRowInserting}
                onRowUpdating={phoneGridRowUpdating}
                onRowDeleting={phoneGridRowDeleting}
              >
                <Editing
                  mode="cell"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                />
                <Column
                  dataField="id"
                  visible={false}
                />
                <Column
                  dataField="number"
                  showEditorAlways={true}
                />
                <Column 
                  dataField="phoneTypeId"
                  showEditorAlways={true}
                >
                  <Lookup
                    dataSource={phoneTypesDataSource}
                    valueExpr="id"
                    displayExpr="value"
                  />
                </Column>
                <Column
                  dataField="preferred"
                  dataType="boolean"
                />
              </DataGrid>
            </GroupItem>
            <GroupItem colCount={1} caption="Email Addresses">
              <DataGrid
                id={emailGridName}
                ref={emailGridRef}
                dataSource={CompanyContact.emailAddresses}
              >
                <Editing
                  mode="cell"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                />
                <Column
                  dataField="id"
                  visible={false}
                />
                <Column
                  dataField="address"
                  showEditorAlways={true}
                />
                <Column
                  dataField="preferred"
                  dataType="boolean"
                />
              </DataGrid>
            </GroupItem>
          </GroupItem>
        </Form>
      </div>

    );
  }

  const companyTitleRender = (company) => <span>{company.companyName}</span>;

  const relationshipEditorOptions = {
    dataSource: clientRelationshipDataSource,
    searchEnabled: true,
    displayExpr: "value",
    valueExpr: "id"
  }

  const priorityEditorOptions = {
    dataSource: contactPrioritiesDataSource,
    searchEnabled: true,
    displayExpr: "value",
    valueExpr: "id"
  }

  const representativeEditorOptions = {
    dataSource: representativeDataSource,
    searchEnabled: true,
    displayExpr: "value",
    valueExpr: "id"
  }

  function companyContactTabPanelInitialized(e) {
  }

  function handleContactEditFormSubmit(e) {
  }

  function onEditContactFormFieldDataChanged(e) {
  }

  function renderContactEditForm() {
    if (showContactEditForm) {
      return (
        <Popup
          fullScreen={true}
          visible={showContactEditForm}
          showCloseButton={true}
          onHiding={() => {
            setShowContactEditForm(false);
          }}
        >
          <TabPanel>
            <Item title="Properties">
              <Form
                id="editingContactForm"
                formData={editingContactData}
                colCount={2}
                onSubmit={handleContactEditFormSubmit}
                onFieldDataChanged={onEditContactFormFieldDataChanged}
              >
                <GroupItem>
                  <Item dataField="firstName" />
                  <Item dataField="lastName" />
                  <Item
                    dataField="relationshipId"
                    editorType="dxSelectBox"
                    editorOptions={relationshipEditorOptions}
                  />
                </GroupItem>
                <GroupItem>
                  <Item
                    dataField="notes"
                    editorType="dxTextArea"
                  />
                  <Item
                    dataField="priorityId"
                    editorType="dxSelectBox"
                    editorOptions={priorityEditorOptions}
                  />
                  <Item
                    dataField="representativeId"
                    editorType="dxSelectBox"
                    edtiorOptions={representativeEditorOptions}
                  />
                </GroupItem>
              </Form>
              <br />
              <TabPanel
                dataSource={editingContactData.companyContacts}
                itemComponent={CompanyContactItem}
                itemTitleRender={companyTitleRender}
                onInitialized={companyContactTabPanelInitialized}
              />
            </Item>
          </TabPanel>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={contactEditFormSaveButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={contactEditFormCancelButtonOptions}
          />
        </Popup>
      );
    }
  }

  function createEstimateButtonClick() {
    setShowContactEditForm(true);
  }

  const createContactButtonOptions = {
    text: "+",
    onClick() {
      createEstimateButtonClick();
    }
  }

  return (
    <React.Fragment>
      <h2 className={setTitleClass()}>Contacts</h2>
      {renderContactEditForm()}
      <DataGrid
        id={contactsGridName}
        ref={contactsGridRef}
        dataSource={contactsDataSource}
        height={() => calculateGridHeight(contactsGridName)}
        remoteOperations={true}
      >
        <Toolbar>
          <Item name="groupPanel" location="before" />
          <Item
            location="after"
            widget="dxButton"
            options={createContactButtonOptions}
          />
        </Toolbar>
        <Paging defaultPageSize={50} />
        <Scrolling
          mode="virtual"
          rowRenderingMode="virtual"
        />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <Editing
          mode="popup"
          allowAdding={false}
          allowDeleting={true}
          allowUpdating={false}
        />
        <Column
          name="editButton"
          type="buttons"
        >
          <Button
            text="edit"
            onClick={(e) => contactGridOnEditingStart(e) }
          />
        </Column>
        <Column dataField="contactId" visible={false} />
        <Column dataField="firstName" sortIndex={1} sortOrder="asc" />
        <Column dataField="lastName" sortIndex={0} sortOrder="asc" />
        <Column dataField="companyName" />
        <Column dataField="jobTitle" />
        <Column dataField="email" />
        <Column dataField="phone" />
        <Column
          name="deleteButton"
          type="buttons"
        >
          <Button name="delete" />
          
        </Column>
      </DataGrid>
    </React.Fragment>
  );
}