import { acquireTokenAsync } from "./auth";

export async function fetchApi(url, method, body) {
  const tokenResponse = await acquireTokenAsync();
  const options = {
    credentials: "include",
    method: method,
    body: body,
  };
  if (tokenResponse) {
    options.headers = new Headers({ 'Authorization': 'Bearer ' + tokenResponse.accessToken });
  }
  const response = await fetch(url, options);
  if (response.status === 200) {
    return await response.json();
  } else {
    const { status, statusText } = response;
    const message = `Error calling fetch api: ${status} ${statusText}`;
    console.log(message);
    throw message;
  }
}


