import { createStore } from "devextreme-aspnet-data-nojquery";
import { setupRequestAsync } from "../../utils/auth";
import { fetchApi } from "../../utils/fetch-api";

const {
  squireApiConfig: { url },
} = window.env;

export default class EstimateDivisionsApiService {
  getEstimateDivisionsDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimate-divisions/`,
      deleteUrl: `${url}/v1/estimate-divisions/`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  async getEstimateDivisionDetails(estimateDivisionId) {
    const fetchUrl = `${url}/v1/estimate-divisions/${estimateDivisionId}`;
    return await fetchApi(fetchUrl, "GET");
  }

  async createEstimateDivision(values) {
    const fetchUrl = `${url}/v1/estimate-divisions/`;
    const formBody = new URLSearchParams({
      values: JSON.stringify(values),
    });
    await fetchApi(fetchUrl, "POST", formBody);
  }

  async updateEstimateDivision(estimateDivisionId, values) {
    const fetchUrl = `${url}/v1/estimate-divisions/`;
    const formBody = new URLSearchParams({
      estimateDivisionId: estimateDivisionId,
      values: JSON.stringify(values),
    });
    await fetchApi(fetchUrl, "PUT", formBody);
  }

  getEstimateScopesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimate-divisions/estimate-scopes`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  getSubtradesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimate-divisions/subtrades`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }
}