import { createStore } from "devextreme-aspnet-data-nojquery";
import { setupRequestAsync } from "../../utils/auth";

const {
  squireApiConfig: { url },
} = window.env;

export default class ConstructionTypeApiService {
  getConstructionTypeLookupDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/construction-types/`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }
}
