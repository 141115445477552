import { createStore } from "devextreme-aspnet-data-nojquery";
import { setupRequestAsync } from "../../utils/auth";
import { fetchApi } from "../../utils/fetch-api";

const {
  squireApiConfig: { url },
} = window.env;

export default class RateSheetApiService {
  getEstimateScopesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimate-scopes/`,
      deleteUrl: `${url}/v1/estimate-scopes/`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  async getEstimateScopeDetails(estimateScopeId) {
    const fetchUrl = `${url}/v1/estimate-scopes/${estimateScopeId}`;
    return await fetchApi(fetchUrl, "GET");
  }

  async createEstimateScope(values) {
    const fetchUrl = `${url}/v1/estimate-scopes/`;
    const formBody = new URLSearchParams({
      values: JSON.stringify(values),
    });
    await fetchApi(fetchUrl, "POST", formBody);
  }

  async updateEstimateScope(estimateScopeId, values) {
    const fetchUrl = `${url}/v1/estimate-scopes/`;
    const formBody = new URLSearchParams({
      estimateScopeId: estimateScopeId,
      values: JSON.stringify(values),
    });
    await fetchApi(fetchUrl, "PUT", formBody);
  }

  getEstimateDivisionsDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimate-scopes/estimate-divisions/`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }
}