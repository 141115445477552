import React from "react";
import "./home.scss";
import logo from "./wk-logo.png";
import { setTitleClass } from "../../utils/ui";

export default function Home() {

  return (
    <React.Fragment>
      <h2 className={setTitleClass()}>Home</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"logos-container"}>
            <img src={logo} alt="White Knight Construction ogo" />
          </div>

          <p>Welcome to Squire! Use the links on the left to get started.</p>
          <p className={setTitleClass()}>This application is running in the '{ process.env.NODE_ENV }' environment.</p>
        </div>
      </div>
    </React.Fragment>
  );
}
