import { createStore } from "devextreme-aspnet-data-nojquery";
import { fetchApi } from "../../utils/fetch-api";
import { setupRequestAsync } from "../../utils/auth";

const {
  squireApiConfig: { url },
} = window.env;

export default class CashflowApiService {
  getCashflowDataSource() {
    return createStore({
      key: "projectId",
      loadUrl: `${url}/v1/cashflow/`,
      insertUrl: `${url}/v1/cashflow/`,
      updateUrl: `${url}/v1/cashflow/`,
      deleteUrl: `${url}/v1/cashflow/`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  getInternalCostsDataSource(key) {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/cashflow/${key}/internal-costs`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  getPurchaseInvoiceDataSource(key) {
    return createStore({
      key: "purchaseInvoiceId",
      loadUrl: `${url}/v1/cashflow/${key}/purchase-invoices`,
      updateUrl: `${url}/v1/cashflow/${key}/make-payments`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  getSalesInvoiceDataSource(key) {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/cashflow/${key}/sales-invoices`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  async updateOverheadPercentage(id, value) {
    const fetchurl = `${url}/v1/cashflow/`;
    const values = {
      overheadPercentage: value,
    };
    const formBody = new URLSearchParams({
      key: id,
      values: JSON.stringify(values),
    });
    return await fetchApi(fetchurl, "PUT", formBody);
  }
}
