import
React,
{
  useRef,
  useState,
} from "react";
import DataGrid, {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  Selection,
  Editing,
  Lookup,
  Summary,
  Item,
  Toolbar,
  TotalItem,
  GroupItem,
  Button,
  Sorting,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import Form from 'devextreme-react/form';
import
{
  currencyFormat,
  dateFormat
} from "../../utils/formatting";
import "./payments.module.scss";
import { setTitleClass } from "../../utils/ui";

export default function Payments(props) {
  const paymentsApiService = new props.paymentsApiService();
  const paymentStatusLookupApiService =
    new props.paymentStatusLookupApiService();

  const paymentsDataSource = paymentsApiService.getPaymentsDataSource();
  const allPaymentsDataSource = paymentsApiService.getAllPaymentsDataSource();
  const paymentStatusLookupDataSource =
    paymentStatusLookupApiService.getPaymentStatusDataSource();

  const [showAllPayments, setShowAllPayments] = useState(false);
  const [gridDataSource, setGridDataSource] = useState(paymentsDataSource);

  const [showEditForm, setShowEditForm] = useState(false);
  const [rowData, setRowData] = useState();


  const dataGridRef = useRef(null);

  function refreshGrid() {
    dataGridRef.current.instance.refresh();
  }



  function updatePaymentsToPaid() {
    var ids = dataGridRef.current.instance.getSelectedRowKeys();
    paymentsApiService.updateStatus("Paid", ids)
      .then(() => {
        refreshGrid()
      }
    );
  }

  function updatePaymentsToApproved() {
    var ids = dataGridRef.current.instance.getSelectedRowKeys();
    paymentsApiService.updateStatus("Approved", ids)
      .then(() => {
        refreshGrid()
      }
    );
  }



  function toggleShowPaidPayments() {
    //If showAllPayments is currently false, that means we are about to turn it to true
    //so we want to show all payments after the render.
    if (showAllPayments === false) {
      setGridDataSource(allPaymentsDataSource);
      setShowAllPayments(true);
    }
    else {
      setGridDataSource(paymentsDataSource);
      setShowAllPayments(false);
    }
  }

  const paidButtonOptions = {
    text: "Mark Selected Payments as Paid",
    width: "300px",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      updatePaymentsToPaid();
    },
  };

  const approveButtonOptions = {
    text: "Approve Selected Payments",
    width: "300px",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      updatePaymentsToApproved();
    },
  };

  const showPaidSwitchOptions = {
    value: showAllPayments,
    onValueChanged() {
      toggleShowPaidPayments();
    }
  }

  function detailsButtonClick(e) {
    setRowData(e.row.data);
    setShowEditForm(true);
  }

  const paymentFormEditorOptions = { readOnly: true };

  function renderDetailPopup() {
    if (showEditForm) {
      return (
        <Popup
          showCloseButton={true}
          visible={showEditForm}
          onHiding={() => { setShowEditForm(false); }}
        >
          <Form
            formData={rowData}
            colCount="2"
          >
            <Item
              dataField="invoiceNumber"
              editorOptions={paymentFormEditorOptions}
            />
            <Item
              dataField="invoiceDate"
              editorType="dxDateBox"
              editorOptions={paymentFormEditorOptions}
            />
            <Item
              dataField="companyName"
              editorOptions={paymentFormEditorOptions}
            />
            <Item
              dataField="jobName"
              editorOptions={paymentFormEditorOptions}
            />
            <Item
              dataField="submittedDate"
              editorType="dxDateBox"
              editorOptions={paymentFormEditorOptions}
            />
            <Item
              dataField="submittedBy"
              editorOptions={paymentFormEditorOptions}
            />
            <Item
              dataField="approvedDate"
              editorType="dxDateBox"
              editorOptions={paymentFormEditorOptions}
            />
            <Item
              dataField="approvedBy"
              editorOptions={paymentFormEditorOptions}
            />
            <Item
              dataField="paidDate"
              editorType="dxDateBox"
              editorOptions={paymentFormEditorOptions}
            />
            <Item
              dataField="paidBy"
              editorOptions={paymentFormEditorOptions}
            />
          </Form>

        </Popup>
      );
    }
  }

  return (
    <React.Fragment>
      {renderDetailPopup()}
      <h2 className={setTitleClass()}>Payments</h2>
      <DataGrid
        dataSource={gridDataSource}
        ref={dataGridRef}>
        <Toolbar>
          <Item name="groupPanel" location="before" />
          <Item
            name="showPaid"
            location="before"
            widget="dxSwitch"
            text="Show Paid Payments"
            options={showPaidSwitchOptions}
          />
          <Item
            name="paid"
            location="center"
            widget="dxButton"
            options={paidButtonOptions}
          />
          <Item
            name="approve"
            location="after"
            widget="dxButton"
            options={approveButtonOptions}
          />
        </Toolbar>
        <Selection mode="multiple" showCheckBoxesMode="always" />
        <FilterRow visible={true} />
        <Editing
          mode="cell"
          allowUpdating={true}
          allowAdding={false}
          allowDeleting={true}
        />
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={false} />
        <Sorting mode="multiple" />
        <Column dataField="companyName" groupIndex={0} allowEditing={false} />
        <Column dataField="jobName" allowEditing={false} />
        <Column dataField="invoiceDate" allowEditing={false} defaultSortOrder="asc" dataType="date" format={dateFormat} />
        <Column dataField="invoiceNumber" allowEditing={false} />
        <Column
          dataField="value"
          format={currencyFormat}>
        </Column>
        <Column
          caption="Status"
          dataField="paymentStatusId"
          allowEditing={false}
        >
          <Lookup
            dataSource={paymentStatusLookupDataSource}
            valueExpr="id"
            displayExpr="value"
          />
        </Column>
        <Column name="buttons" type="buttons">
          <Button
            icon="info"
            onClick={(e) => detailsButtonClick(e)}
          />
          <Button name="delete" />
        </Column>
        <Summary>
          <TotalItem
            column="value"
            summaryType="sum"
            valueFormat={currencyFormat}
            displayFormat="Total Payments: {0}"
          />
          <GroupItem
            column="value"
            summaryType="sum"
            valueFormat={currencyFormat}
            alignByColumn={true}
            displayFormat="Total for Company: {0}"
          />
        </Summary>
      </DataGrid>
    </React.Fragment>
  );
}
