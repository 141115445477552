import React,
{
  useRef,
  useState,
}
from "react";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Scrolling,
  Button,
  Editing,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import {
  Popup,
  ToolbarItem
} from 'devextreme-react/popup';
import Form, {
  GroupItem,
  Label,
  RequiredRule,
} from "devextreme-react/form";
import { CheckBox } from 'devextreme-react/check-box';
import Box from 'devextreme-react/box';
import TabPanel from "devextreme-react/tab-panel";
import { calculateGridHeight } from '../../utils/ui';
import { setTitleClass } from "../../utils/ui";

export default function Companies(props) {
  const companiesApiService = new props.companyApiService();
  const companiesDataSource = companiesApiService.getCompaniesDataSource();
  const industryTypeDataSource = companiesApiService.getIndustryTypesDataSource();

  const companiesDataGridName = "CompaniesDataGrid";
  const companiesDataGridRef = useRef(null);

  const [showCompanyEditForm, setShowCompanyEditForm] = useState();
  const [editingCompanyViewData, setEditingCompanyViewData] = useState();
  const [showEstimateDivisions, setShowEstimateDivisions] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  const editingCompanyDtoData = useRef(
    {
      estimateDivisions: {
        toAdd: [],
        toRemove: [],
      }
    }
  );
  const editFormTitle = useRef('');
  const editFormRef = useRef();


  const industryTypeEditorOptions = {
    dataSource: industryTypeDataSource,
    searchEnabled: true,
    displayExpr: "value",
    valueExpr: "id",
  }

  const subtradeEditorOptions = {
    hint: "Select if you want this company to show in the subtrades drop down on the estimate sheet.",
    onValueChanged(e) {
      setShowEstimateDivisions(e.value);
    }
  }

  function estimateDivisionsCheckValueChanged(e, estimateDivision) {
    var toRemoveArray = editingCompanyDtoData.current.estimateDivisions.toRemove;
    var toAddArray = editingCompanyDtoData.current.estimateDivisions.toAdd;
    if (e.value) {
      var index = editingCompanyDtoData.current.estimateDivisions.toRemove.indexOf(estimateDivision.id);
      if (index > -1) {
        toRemoveArray.filter(id => id === estimateDivision.id);
      }
      toAddArray.push(estimateDivision.id);
    }
    else {
      var index2 = editingCompanyDtoData.current.estimateDivisions.toAdd.indexOf(estimateDivision.id);
      if (index2 > -1) {
        toAddArray.filter(id => id === estimateDivision.id);
      }
      toRemoveArray.push(estimateDivision.id);
    }
  }

  function estimateDivisionCheckInitialized(e, estimateDivision) {
    e.component.option('value', estimateDivision.checked);
  }

  const companyEditFormSaveButtonOptions = {
    text: "Save",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      companyEditFormSaveButtonOnClick();
    }
  }

  async function companyEditFormSaveButtonOnClick() {
    var formInstance = editFormRef.current.instance;
    var validationResult = formInstance.validate();
    if (validationResult.isValid) {
      await saveCompanyData()
        .then(() => {
          cleanupAndCloseEditForm();
        });
    }
  }

  function cleanupAndCloseEditForm() {
    setIsCreate(false);
    setShowCompanyEditForm(false);
    setEditingCompanyViewData();
    resetCompanyDto();
  }

  async function saveCompanyData() {
    if (isCreate) {
      await companiesApiService.createCompany(editingCompanyDtoData.current);
    }
    else {
      await companiesApiService.updateCompany(editingCompanyViewData.id, editingCompanyDtoData.current);
    }
  }

  const companyEditFormCancelButtonOptions = {
    text: "Cancel",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      companyEditFormCancelButtonOnClick();
    }
  }

  function companyEditFormCancelButtonOnClick() {
    cleanupAndCloseEditForm();
  }

  function companyEditFormFieldDataChanged(e) {
    if (e.dataField === "name") {
      var dtoHasName = editingCompanyDtoData.current.hasOwnProperty("name");
      if (!dtoHasName) {
        Object.defineProperty(editingCompanyDtoData.current, "name", { value: "", writable: true, enumerable: true });
      }
      editingCompanyDtoData.current.name = e.value;
    }
    if (e.dataField === "description") {
      var dtoHasDescription = editingCompanyDtoData.current.hasOwnProperty("description");
      if (!dtoHasDescription) {
        Object.defineProperty(editingCompanyDtoData.current, "description", { value: "", writable: true, enumerable: true });
      }
      editingCompanyDtoData.current.description = e.value;
    }
    if (e.dataField === "industryTypeId") {
      var dtoHasIndustryTypeId = editingCompanyDtoData.current.hasOwnProperty("industryTypeId");
      if (!dtoHasIndustryTypeId) {
        Object.defineProperty(editingCompanyDtoData.current, "industryTypeId", { value: -1, writable: true, enumerable: true });
      }
      editingCompanyDtoData.current.industryTypeId = e.value;
    }
    if (e.dataField === "subtrade") {
      var dtoHasSubtrade = editingCompanyDtoData.current.hasOwnProperty("subtrade");
      if (!dtoHasSubtrade) {
        Object.defineProperty(editingCompanyDtoData.current, "subtrade", { value: false, writable: true, enumerable: true });
      }
      editingCompanyDtoData.current.subtrade = e.value;
    }
    if (e.dataField === "operatingAs") {
      var dtoHasOperatingAs = editingCompanyDtoData.current.hasOwnProperty("operatingAs");
      if (!dtoHasOperatingAs) {
        Object.defineProperty(editingCompanyDtoData.current, "operatingAs", { value: "", writable: true, enumerable: true });
      }
      editingCompanyDtoData.current.operatingAs = e.value;
    }
  }

  function renderCompanyEditForm() {
    if (showCompanyEditForm) {
      return (
        <Popup
          fullScreen={true}
          visible={showCompanyEditForm}
          showCloseButton={true}
          title={editFormTitle.current}
          onHiding={() => {
            cleanupAndCloseEditForm();
          } }
        >
          <TabPanel>
            <Item
              title="Properties"
            >
              <Form
                id="editingCompanyForm"
                formData={editingCompanyViewData}
                onFieldDataChanged={companyEditFormFieldDataChanged}
                ref={editFormRef}
              >
                <GroupItem>
                  <Item dataField="sageName">
                    <Label
                      text="Name as it appears in Sage"
                    />
                    <RequiredRule />
                  </Item>
                  <Item dataField="operatingAs" />
                  <Item dataField="description" />
                  <Item
                    dataField="industryTypeId"
                    editorType="dxSelectBox"
                    editorOptions={industryTypeEditorOptions}
                  >
                    <Label
                      text="Industry"
                    />
                  </Item>
                  <Item
                    dataField="subtrade"
                    editorType="dxCheckBox"
                    editorOptions={subtradeEditorOptions}
                  />
                </GroupItem>
                <div className="spacedDiv" />
                <GroupItem
                  caption="Estimate Divisions"
                  visible={showEstimateDivisions}
                >
                  <div className="spacedDiv">Selected estimate divisions will show this company in the subtrades dropdown on the estimate sheet.</div>
                  {editingCompanyViewData.estimateDivisions.$values && editingCompanyViewData.estimateDivisions.$values.map((estimateDivision) => (
                    <Box
                      direction="row"
                      width="400px"
                      height="40px"
                      crossAlign="center"
                      key={estimateDivision.id}
                    >
                      <Item
                        ratio={0}
                        baseSize={75}
                      >
                        <div></div>
                        <CheckBox
                          onInitialized={(e) => estimateDivisionCheckInitialized(e, estimateDivision)}
                          onValueChanged={(e) => estimateDivisionsCheckValueChanged(e, estimateDivision)}
                        />
                      </Item>
                      <Item
                        ratio={1}
                      >
                        {estimateDivision.name}
                      </Item>
                    </Box>
                  )) }
                </GroupItem>
              </Form>
            </Item>
          </TabPanel>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={companyEditFormSaveButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={companyEditFormCancelButtonOptions}
          />
        </Popup>
      )
    }
  }

  async function companyGridOnEditingStart(e) {
    setIsCreate(false);
    await loadCompanyData(e.row.data.id)
      .then(() => {
        setShowCompanyEditForm(true);
      });
    editFormTitle.current = "Editing: " + e.row.data.screenName;
  }

  async function loadCompanyData(id) {
    var companyData = await companiesApiService.getCompanyDetails(id);
    setEditingCompanyViewData(companyData);
    setShowEstimateDivisions(companyData.subtrade);
  }

  async function createCompanyButtonClick() {
    await loadCompanyData(0)
      .then(() => {
        editFormTitle.current = "New Company";
        setIsCreate(true);
        setShowCompanyEditForm(true);
      });
  }

  function resetCompanyDto() {
    editingCompanyDtoData.current = {
      estimateDivisions: {
        toAdd: [],
        toRemove: []
      }
    }
  }

  const createCompanyButtonOptions = {
    text: "+",
    onClick() {
      createCompanyButtonClick();
    }
  }

  return (
    <React.Fragment>
      <h2 className={setTitleClass()}>Companies</h2>
      { renderCompanyEditForm() }
      <DataGrid
        className={"dx-card wide-card"}
        dataSource={companiesDataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        ref={companiesDataGridRef}
        remoteOperations={true}
        showColumnLines={true}
        id={companiesDataGridName}
        height={() => calculateGridHeight(companiesDataGridName) }
      >
        <Toolbar>
          <Item
            name="groupPanel"
            location="before"
          />
          <Item
            location="after"
            widget="dxButton"
            options={createCompanyButtonOptions}
          />
        </Toolbar>
        <Paging defaultPageSize={50} />
        <Scrolling
          mode='virtual'
          rowRenderingMode='virtual'
        />
        <FilterRow visible={true} />
        <Editing
          mode="cell"
          allowUpdating={true}
          allowAdding={false}
          allowDeleting={true}
        >
        </Editing>
        <Column
          name="editButton"
          type="buttons"
        >
          <Button
            icon="edit"
            onClick={(e) => companyGridOnEditingStart(e)}
          />
        </Column>
        <Column
          dataField="name"
          sortIndex={0}
          sortOrder="asc"
          caption="Name as it appears in Sage"
          allowEditing={false}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="operatingAs"
          
        />
        <Column dataField="subtrade" />
        <Column
          dataField="screenName"
          visible={false}
        />
        <Column
          name="deleteButton"
          type="buttons"
        >
          <Button
            icon="trash"
            name="delete"
          />
        </Column>
      </DataGrid>
    </React.Fragment>
  );
}
