import React,
{
  useRef,
  useState,
}
  from "react";
import DataGrid, {
  Column,
  Paging,
  Button,
  Editing,
  Scrolling,
  Toolbar,
} from "devextreme-react/data-grid";
import TabPanel, {
  Item
} from "devextreme-react/tab-panel";
import {
  Popup,
  ToolbarItem
} from 'devextreme-react/popup';
import Form, {
  RequiredRule,
} from "devextreme-react/form";
import { CheckBox } from 'devextreme-react/check-box';
import Box from 'devextreme-react/box';
import { calculateGridHeight } from '../../utils/ui';
import { setTitleClass } from "../../utils/ui";

export default function EstimateDivisions(props) {
  const estimateDivisionsApiService = new props.estimateDivisionsApiService();
  const estimateDivisionsDataSource = estimateDivisionsApiService.getEstimateDivisionsDataSource();

  const estimateDivisionsDataGridName = "EstimateDivisionsDataGrid";
  const estimateDivisionsDataGridRef = useRef(null);

  const [showEstimateDivisionEditForm, setShowEstimateDivisionEditForm] = useState();
  const [editingEstimateDivisionViewData, setEditingEstimateDivisionViewData] = useState();
  const editingEstimateDivisionDtoData = useRef(
    {
      //TODO: What does this collection of scopes need to look like to work?
      //scopes: {
      //  toCreate: [],
      //  toUpdate: [],
      //  toDelete: []
      //},
      subtrades: {
        toAdd: [],
        toRemove: []
      }
    }
  );
  const [isCreate, setIsCreate] = useState(false);
  //const [moveButtonEnabled, setMoveButtonEnabled] = useState(false);
  //const [editFormTitle, setEditFormTitle] = useState('');
  const editFormTitle = useRef('');
  const editFormRef = useRef();

  const estimateDivisionEditFormSaveButtonOptions = {
    text: "Save",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      estimateDivisionEditFormSaveButtonOnClick();
    }
  }

  async function estimateDivisionEditFormSaveButtonOnClick() {
    var formInstance = editFormRef.current.instance;
    var validationResult = formInstance.validate();
    if (validationResult.isValid) {
      await saveEstimateDivisionData()
        .then(() => {
          cleanupAndCloseEditForm();
        });
    }
  }

  async function saveEstimateDivisionData() {
    if (isCreate) {
      await estimateDivisionsApiService.createEstimateDivision(editingEstimateDivisionDtoData.current);
    }
    else {
      await estimateDivisionsApiService.updateEstimateDivision(editingEstimateDivisionViewData.id, editingEstimateDivisionDtoData.current);
    }
  }

  function cleanupAndCloseEditForm() {
    setIsCreate(false);
    setShowEstimateDivisionEditForm(false);
    setEditingEstimateDivisionViewData();
    resetEstimateDivisionDto();
  }

  const estimateDivisionEditFormCancelButtonOptions = {
    text: "Cancel",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      estimateDivisionEditFormCancelButtonOnClick();
    }
  }

  function estimateDivisionEditFormCancelButtonOnClick() {
    cleanupAndCloseEditForm();
  }

  function estimateDivisionFormFieldDataChanged(e) {
    if (e.dataField === "name") {
      var dtoHasName = editingEstimateDivisionDtoData.current.hasOwnProperty("name");
      if (!dtoHasName) {
        Object.defineProperty(editingEstimateDivisionDtoData.current, "name", { value: "", writable: true, enumerable: true });
      }
      editingEstimateDivisionDtoData.current.name = e.value;
    }
  }

  function subtradeCheckValueChanged(e, subtrade) {
    var toRemoveArray = editingEstimateDivisionDtoData.current.subtrades.toRemove;
    var toAddArray = editingEstimateDivisionDtoData.current.subtrades.toAdd;
    if (e.value) {
      //This row was checked, first check if this subtrade is already in the 'toRemove' collection
      var index = editingEstimateDivisionDtoData.current.subtrades.toRemove.indexOf(subtrade.id);
      if (index > -1) {
        toRemoveArray.filter(id => id === subtrade.id);
      }
      toAddArray.push(subtrade.id);
    }
    else {
      var index2 = editingEstimateDivisionDtoData.current.subtrades.toAdd.indexOf(subtrade.id);
      if (index2 > -1) {
        toAddArray.filter(id => id === subtrade.id);
      }
      toRemoveArray.push(subtrade.id);
    }
    ////Now, replace the toAdd and toRemove collections on the ref dto with our new arrays
    //editingEstimateDivisionDtoData.current =
    //{
    //  ...editingEstimateDivisionDtoData,
    //  subtrades: {
    //    toAdd: [...toAddArray],
    //    toRemove: [...toRemoveArray],
    //  }
    //};
  }

  function subtradeCheckIntialized(e, subtrade) {
    e.component.option('value', subtrade.checked);
  }

  function renderEstimateDivisionEditForm() {
    if (showEstimateDivisionEditForm) {
      return (
        <Popup
          fullScreen={true}
          visible={showEstimateDivisionEditForm}
          showCloseButton={true}
          onHiding={() => {
            cleanupAndCloseEditForm();
          }}
          title={editFormTitle.current}
        >
          <TabPanel>
            <Item title="Properties">
              <Form
                id="editingEstimateDivisionForm"
                formData={editingEstimateDivisionViewData}
                onFieldDataChanged={estimateDivisionFormFieldDataChanged}
                ref={editFormRef}
              >
                <Item
                  dataField="name"
                >
                  <RequiredRule />
                </Item>
              </Form>
            </Item>
            {/*<Item title="Scopes">*/}
            {/*  <div className="spacedDiv">Please select the scopes you want to add to this division.  NOTE: Scopes added to this division will be removed from their previous division.  Scopes can only be in a single division.</div>*/}
            {/*  <Button*/}
            {/*    text="Add Scopes..."*/}
            {/*    onClick={addScopesButtonClick}*/}
            {/*    type="default"*/}
            {/*    stylingMode="outlined"*/}
            {/*  />*/}
            {/*  <Button*/}
            {/*    text="Move Selected Scopes..."*/}
            {/*    onClick={moveScopesButtonClick}*/}
            {/*    type="default"*/}
            {/*    stylingMode="outlined"*/}
            {/*  />*/}
            {/*  <List*/}
            {/*    ref={scopesListRef}*/}
            {/*    dataSource={editingEstimateDivisionViewData.relatedEstimateScopes.$values}*/}
            {/*    showSelectionControls={true}*/}
            {/*    selectionMode="all"*/}
            {/*    selectAllMode="all"*/}
            {/*    selectByClick={true}*/}
            {/*    onSelectionChanged={scopesListSelectionChanged}*/}
            {/*    pageLoadMode="scrollBottom"*/}
            {/*    displayExpr="name"*/}
            {/*    keyExpr="id"*/}
            {/*  >*/}
            {/*  </List>*/}
            {/*</Item>*/}
            <Item title="Sub-trades">
              <div className="spacedDiv">Select the companies you want to show in the subtrades drop down on the estimate sheet. NOTE: Only companies marked as 'Subtrades' show in this list.</div>
              {editingEstimateDivisionViewData.subtrades.$values && editingEstimateDivisionViewData.subtrades.$values.map((subtrade) => (
                <Box
                  direction="row"
                  width="400px"
                  height="40px"
                  crossAlign="center"
                  key={subtrade.id}
                >
                  <Item
                    ratio={0}
                    baseSize={75}
                  >
                    <div></div>
                    <CheckBox
                      onInitialized={(e) => subtradeCheckIntialized(e, subtrade) }
                      onValueChanged={(e) => subtradeCheckValueChanged(e, subtrade)}
                    />
                  </Item>
                  <Item
                    ratio={1}
                  >
                    {subtrade.name}
                  </Item>
                </Box>
              ))}
            </Item>
          </TabPanel>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={estimateDivisionEditFormSaveButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={estimateDivisionEditFormCancelButtonOptions}
          />
        </Popup>
      );
    }
  }

  async function estimateDivisionGridOnEditingStart(e) {
    setIsCreate(false);
    await loadEstimateDivisionData(e.row.data.id)
      .then(() => {
        setShowEstimateDivisionEditForm(true);
      });
    editFormTitle.current = "Editing: " + e.row.data.name;
  }

  async function loadEstimateDivisionData(id) {
    var estimateDivisionData = await estimateDivisionsApiService.getEstimateDivisionDetails(id);
    setEditingEstimateDivisionViewData(estimateDivisionData);
  }

  function resetEstimateDivisionDto() {
    editingEstimateDivisionDtoData.current = {
      //TODO: What does this collection of scopes need to look like to work?
      //scopes: {
      //  toCreate: [],
      //  toUpdate: [],
      //  toDelete: []
      //},
      subtrades: {
        toAdd: [],
        toRemove: []
      }
    }
  }

  async function createEstimateDivisionButtonClick() {
    await loadEstimateDivisionData(0)
      .then(() => {
        editFormTitle.current = "New Estimate Division";
        setIsCreate(true);
        setShowEstimateDivisionEditForm(true);
      });
  }

  const createEstimateDivisionButtonOptions = {
    text: '+',
    onClick() {
      createEstimateDivisionButtonClick();
    }
  }

  return (
    <React.Fragment>
      <h2 className={setTitleClass()}>Estimate Divisions</h2>
      { renderEstimateDivisionEditForm() }
      <DataGrid
        className={"dx-card wide-card"}
        dataSource={estimateDivisionsDataSource}
        ref={estimateDivisionsDataGridRef}
        id={estimateDivisionsDataGridName}
        height={() => calculateGridHeight(estimateDivisionsDataGridName) }
      >
        <Paging defaultPageSize={50} />
        <Scrolling
          mode='virtual'
          rowRenderingMode='virtual'
        />
        <Editing
          mode="popup"
          allowUpdating={false}
          allowAdding={false}
          allowDeleting={true}
        >
        </Editing>
        <Toolbar>
          <Item
            location="after"
            widget="dxButton"
            options={createEstimateDivisionButtonOptions}
          />
        </Toolbar>
        <Column
          name="editButton"
          type="buttons"
        >
          <Button
            icon="edit"
            onClick={(e) => estimateDivisionGridOnEditingStart(e)}
          />
        </Column>
        <Column dataField="name" sortIndex={0} sortOrder="asc" />
      </DataGrid>
    </React.Fragment>
  );
}