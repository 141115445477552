import { createStore } from "devextreme-aspnet-data-nojquery";
import { setupRequestAsync } from "../../utils/auth";

const {
  squireApiConfig: { url },
} = window.env;

export default class AccountsPayableApiService {
  getAccountsPayableDataSource() {
    return createStore({
      key: "purchaseInvoiceId",
      loadUrl: `${url}/v1/accounts-payable/`,
      updateUrl: `${url}/v1/accounts-payable/make-payment`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  getAllocationsDataSource(invoiceId) {
    return createStore({
      key: "allocationId",
      loadUrl: `${url}/v1/accounts-payable/${invoiceId}/allocations`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }
}