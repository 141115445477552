import generalStyles from '../themes/general.module.scss';

export function calculateGridHeight(gridId) {
  var grid = document.getElementById(gridId);
  if (null == grid)
    return 0;
  var containerOffset = offset(document.getElementsByClassName("content")[0]);
  return Math.max(window.innerHeight - containerOffset.top - document.getElementById(gridId).offsetTop, 50) - 25;
}

function offset(element) {
  var rect = element.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft, height: rect.height, width: rect.width }
}

export function setAgeCellClass(age) {
  if (age >= 60) {
    return generalStyles.ageStyle60Plus;
  }
  else if (age >= 46 && age < 60) {
    return generalStyles.ageStyle46to60;
  }
  else if (age >= 31 && age <= 45) {
    return generalStyles.ageStyle31to45;
  }
  else {
    return "";
  }
}

export function setTitleClass() {
  if (window.env.REACT_APP_IS_STAGING) {
    return "staging-env";
  }
  else {
    return "content-block";
  }
}