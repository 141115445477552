import Button from "devextreme-react/button";
import { useMsal } from "@azure/msal-react";
import { getAccount } from "../../utils/auth";

export default function SignInButton() {
  const { instance } = useMsal();
  const handleLogout = () => {
    instance.logoutPopup();
  };
  function getUserName() {
    var account = getAccount();
    if (account) {
      return account.username;
    }
  };

  return (
    <div>
      You are logged in as: {getUserName()}
      <Button text="Sign Out" icon="close" onClick={handleLogout}></Button>
    </div>
    
  );
}
