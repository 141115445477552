export const currencyFormat = {
  type: "currency",
  precision: 2,
};

export const shortDateFormat = {
  type: "shortDate",
};

export const percentageFormat = {
  format: "0'%'"
}

export const dateFormat = "MMM dd yyyy"
export const dateTimeFormat = "MMM dd yyyy, hh:mm a"
